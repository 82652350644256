<template>
  <div class="auth-wrapper auth-v1 px-2" v-show="false">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">Homeassistant</h2>
        </b-link>

        <b-card-title class="mb-1"> Email Verify 👋 </b-card-title>
        <b-card-text class="mb-2"> Please Wait System Processing </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    try {
      await this.alert.success({
        title: "Email Verify",
        text: "please login system",
      })
      setTimeout(() => {
        this.$router.push({ name: "auth-login" })
      }, 500)
    } catch (error) {
      console.error(error)
      return ""
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
